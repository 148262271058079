@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-size: 0.875rem;
  /* font-family: "Open Sans",sans-serif!important; */
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.snackbarSuccess {
  background-color: #7cb7b5 !important;
}

.snackbarError {
  background-color: #ff6e6e !important;
}

.g5-background {
  background-color: #fac912;
}
