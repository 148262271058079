@import "styles/main";

.sentry-error-embed-wrapper {
  z-index: 999999999999999999 !important;
}

#root {
  margin-bottom: 70px;
}

.snackbarSuccess {
  background-color: #7cb7b5 !important;
}

.snackbarError {
  background-color: #ff6e6e !important;
}

h1.enable {
  font-size: 2em;
  font-family: "Amethysta", serif !important;
  text-align: center;
  line-height: 1.4em;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  white-space: nowrap;

  span {
    color: #fac912;
    font-family: "Caesar Dressing", cursive !important;
    text-transform: lowercase;
    vertical-align: middle;
    letter-spacing: 0.2em;
  }

  .fire {
    animation: animation 1s ease-in-out infinite alternate;
    -moz-animation: animation 1s ease-in-out infinite alternate;
    -webkit-animation: animation 1s ease-in-out infinite alternate;
    -o-animation: animation 1s ease-in-out infinite alternate;
  }

  .burn {
    animation: animation 0.65s ease-in-out infinite alternate;
    -moz-animation: animation 0.65s ease-in-out infinite alternate;
    -webkit-animation: animation 0.65s ease-in-out infinite alternate;
    -o-animation: animation 0.65s ease-in-out infinite alternate;
  }

  @media (max-width: 500px) {
    font-size: 1em;
  }
}

@keyframes animation {
  0% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #feec85,
      -20px -20px 40px #ffae34, 20px -40px 50px #ec760c,
      -20px -60px 60px #cd4606, 0 -80px 70px #973716, 10px -90px 80px #451b0e;
  }
  100% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #fefcc9,
      -20px -20px 40px #feec85, 22px -42px 60px #ffae34,
      -22px -58px 50px #ec760c, 0 -82px 80px #cd4606, 10px -90px 80px #973716;
  }
}

@-moz-keyframes animation {
  0% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #feec85,
      -20px -20px 40px #ffae34, 20px -40px 50px #ec760c,
      -20px -60px 60px #cd4606, 0 -80px 70px #973716, 10px -90px 80px #451b0e;
  }
  100% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #fefcc9,
      -20px -20px 40px #feec85, 22px -42px 60px #ffae34,
      -22px -58px 50px #ec760c, 0 -82px 80px #cd4606, 10px -90px 80px #973716;
  }
}

@-webkit-keyframes animation {
  0% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #feec85,
      -20px -20px 40px #ffae34, 20px -40px 50px #ec760c,
      -20px -60px 60px #cd4606, 0 -80px 70px #973716, 10px -90px 80px #451b0e;
  }
  100% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #fefcc9,
      -20px -20px 40px #feec85, 22px -42px 60px #ffae34,
      -22px -58px 50px #ec760c, 0 -82px 80px #cd4606, 10px -90px 80px #973716;
  }
}

@-o-keyframes animation {
  0% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #feec85,
      -20px -20px 40px #ffae34, 20px -40px 50px #ec760c,
      -20px -60px 60px #cd4606, 0 -80px 70px #973716, 10px -90px 80px #451b0e;
  }
  100% {
    text-shadow: 0 0 20px #fefcc9, 10px -10px 30px #fefcc9,
      -20px -20px 40px #feec85, 22px -42px 60px #ffae34,
      -22px -58px 50px #ec760c, 0 -82px 80px #cd4606, 10px -90px 80px #973716;
  }
}

.disabled-link {
  pointer-events: none !important;
}
