body {
  --primary-color: #fac90e;
  max-width: 100vw !important;
  overflow-x: hidden !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill:valid,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-transition-delay: 99999s;
  -webkit-text-fill-color: inherit;
}

* {
  outline: none !important;
}

.default-transition {
  transition: all 0.3s;
}

.background-grey {
  background-color: #424447;
}

.background-black {
  background-color: #262626;
}

.primary-color {
  color: var(--primary-color);
}

.bg-aqua {
  background-color: #7cb7b5;
}

.text-aqua {
  color: #7cb7b5;
}

.text-aqua-forced {
  color: #7cb7b5 !important;
}

body,
html {
  background-color: #252525;
}

.open-sans {
  font-family: "Open Sans", sans-serif !important;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.atf * {
  @apply open-sans;
}

.atf-container {
  max-width: 1440px;

  @media (min-width: 1920px) {
    max-width: 1800px;
  }
}

.about *,
.brands * {
  @apply montserrat;
}

.atf {
  max-width: 1266px;
  margin: auto auto auto 0;

  @media (max-width: 1266px) {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}

header {
  border-color: var(--primary-color);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100000000;

  nav {
    height: 70px;
    background-color: #424447;
    padding: 0;
    img {
      max-height: 38px;
      width: auto;
    }

    @media (max-width: 600px) {
      height: 50px;
    }

    .nav-items {
      align-items: center;

      li {
        margin: 0 16px;
        a {
          @apply uppercase;

          font-size: 11px;
          line-height: 1.45;
          letter-spacing: 1.5px;
          color: #f0f0e9;

          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  &.authenticated {
    nav {
      background-color: #fac912;

      .nav-items {
        li {
          a {
            color: #4c4c4e;
          }
        }
      }
    }
  }
}

.atf-head {
  h3 {
    font-size: 2.4vw;
    line-height: 1.21;
  }
  h5 {
    font-size: 1.2vw;
    line-height: 1.74;
  }

  @media (min-width: 1920px) {
    h3 {
      font-size: 56px;
    }

    h5 {
      font-size: 24px;
    }
  }
}

button[disabled] {
  opacity: 0.35;
  color: #fff;
  border-radius: 3px;
  //border: solid 1px #f0f0e9;
  cursor: not-allowed;
  pointer-events: none;
}

.btn {
  border-radius: 3px;
  border: solid 1px #f0f0e9;
  min-width: 60px;
  height: auto;
  padding: 0 12px;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.05, 0.69, 0.14, 1) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f0f0e9;

  &:not(.small-btn) {
    min-height: 40px !important;
    padding: 12px !important;
  }

  &:not(.w-full) {
    width: auto;
  }

  @media (hover: hover) {
    &:hover {
      background-color: #fac912;
      border: solid 1px #fac912;
      color: #424447;
      transition: all 0.4s cubic-bezier(0.05, 0.69, 0.14, 1) !important;
    }
  }

  &,
  &:focus {
    outline: none;
  }

  &.inverted {
    background-color: #fac912;
    border: solid 1px #424447;
    color: #424447;

    &.bordered {
      @media (hover: hover) {
        &:hover {
          border: solid 1px #f0f0e9 !important;
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: #424447;
        border: solid 1px #424447;
        color: #f0f0e9;

        .button-helper {
          color: #f0f0e9 !important;
        }
      }
    }
  }

  &.wheat {
    background-color: #fcda69;
    border: solid 1px #424447;
    color: #252525;

    @media (hover: hover) {
      &:hover {
        background-color: #424447;
        border: solid 1px #fcda69;
        color: #fcda69;
      }
    }
  }

  &.inverted-hollow {
    background-color: transparent;
    border: solid 1px #424447;
    color: #424447;

    @media (hover: hover) {
      &:hover {
        background-color: #424447;
        border: solid 1px #424447;
        color: #f0f0e9;
      }
    }
  }
}

.about {
  max-width: 1440px;
  margin: auto;
  .about-item > div {
    @apply m-8;
  }

  @media (min-width: 1920px) {
    max-width: 1800px;
  }
}

.about-item {
  flex: 0 0 33.33333333%;
  .primary-title {
    font-size: 1.8vw;
    line-height: 1.71;
    letter-spacing: 0.3vw;
    text-align: center;
    color: var(--primary-color);
  }
  .description {
    line-height: 1.52;
    letter-spacing: 0.12vw;
    font-size: 0.8vw;
  }

  @media (min-width: 1920px) {
    .primary-title {
      font-size: 40px;
    }

    .description {
      font-size: 20px;
    }
  }
}

.about .image {
  width: 100%;
  max-width: 300px;
  img {
    max-width: 100%;
    height: auto;
  }
}

.brands-container {
  max-width: 1600px;
  margin: auto;
}

.brands-title {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  text-align: center;
  color: #f0f0e9;

  @media (max-width: 500px) {
    font-size: 28px;
  }
}

.brand-item {
  text-align: center;
  margin-top: 23px;
  margin-bottom: 23px;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    width: auto;
    height: auto;
  }
}

footer {
  background-color: #101010;

  a:hover {
    color: var(--primary-color);
  }
}

.footer-content {
  max-width: 1200px;
  margin: auto;
}

a.social-icon {
  @apply rounded-full h-8 w-8 flex items-center justify-center default-transition;
  color: #000;

  &:hover {
    opacity: 0.7;
  }

  &:not(:first-of-type) {
    margin-left: 10px;
  }
}

@media (max-width: 750px) {
  .atf-image img {
    max-width: 80%;
    height: auto;
  }
  .atf-head {
    h3 {
      font-size: 7.33vw;
      line-height: 0.87;
    }
    h5 {
      font-size: 2.66vw;
      line-height: 1.35;
    }
  }
  .about-item {
    flex-wrap: wrap;
    position: relative;
    margin-top: 40px !important;
    .primary-title {
      font-size: 24px;
    }
    .description {
      line-height: 1.2;
      letter-spacing: 0.4vw;
      font-size: 13px;
    }
  }
  .about {
    .image {
      max-width: 200px;
    }
  }
  .brands {
    margin-top: 100px;
  }
  a.social-icon {
    @apply rounded-full h-8 w-8 flex items-center justify-center;
    color: #000;
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
  .hide-mobile {
    display: none;
  }
}

.email-input {
  border-radius: 2.5px;
  border: solid 1px #a1a1a1;
  background-color: #ebebeb;
  width: 100%;
  max-width: 450px;
  height: 45px;
  font-size: 13px;
  line-height: 3.62;
  letter-spacing: 0.8px;
  padding: 16px 25px;
  &::placeholder {
    color: #101010;
  }
}

.store,
.profile {
  nav {
    background-color: #fac912;
    height: 115px;
    padding: 0 !important;
  }
  .nav-container {
    height: 70px;
    padding: 16px 0;
  }
}

.nav-bottom-section {
  height: 45px;
  background-color: #7cb7b5;
}

.influencer-avatar {
  width: 192px !important;
  height: 192px !important;
}

.influencer-name {
  font-family: "Open Sans", sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: 1.2;
  color: #f0f0e9;
}

.influencer-description {
  margin: 16px 0;
  font-size: 16px;
  line-height: 1.2;
  color: #f0f0e9;
}

.carousel-item-card {
  width: 220px;
  height: 350px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 16px;
  margin: 14px auto;
}

.btn.btn-action {
  background-color: var(--primary-color);
  font-size: 16px;
  padding: 6px 18px;
  margin: 0;
  white-space: nowrap;
}

.campaign {
  border-radius: 5px;
}

.carousel {
  position: relative;

  .items-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 50px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .arrow {
      background: #424447;
      color: #fff !important;
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 99999999;
      cursor: pointer;
    }
  }
}

.bg-slate {
  background-color: #5c5d5f;
}

.bg-charcoal {
  background-color: #424447;
}

.text-charcoal {
  color: #424447;
}

.text-slate {
  color: #8a8a8a;
}

.loading-container {
  width: 100vw;
  height: 100vh;
}

.h-12-imp {
  height: 3rem !important;
}

.pl-8-imp {
  padding-left: 2rem !important;
}

.font-bold-imp {
  font-weight: 700 !important;
}

button:enabled:not(.btn-ghost):not(.MuiButtonBase-root):not(.no-effects) {
  &:hover {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
    transition: all 0.4s cubic-bezier(0.05, 0.69, 0.14, 1);
  }

  &:focus {
    box-shadow: none;
    transition: all 0.4s cubic-bezier(0.05, 0.69, 0.14, 1);
  }
}

.z-depth-0 {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

/* 2dp elevation modified*/
.z-depth-1,
nav,
.card-panel,
.card,
.toast,
.btn,
.btn-large,
.btn-small,
.btn-floating,
.dropdown-content,
.collapsible,
.sidenav {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.z-depth-1-half,
.btn:hover,
.btn-large:hover,
.btn-small:hover,
.btn-floating:hover {
  -webkit-box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14),
    0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

/* 6dp elevation modified*/
.z-depth-2 {
  -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3);
}

/* 12dp elevation modified*/
.z-depth-3 {
  -webkit-box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

/* 16dp elevation */
.z-depth-4 {
  -webkit-box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -7px rgba(0, 0, 0, 0.2);
}

/* 24dp elevation */
.z-depth-5,
.modal {
  -webkit-box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
  box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 11px 15px -7px rgba(0, 0, 0, 0.2);
}

.hoverable {
  -webkit-transition: -webkit-box-shadow 0.25s;
  transition: -webkit-box-shadow 0.25s;
  transition: box-shadow 0.25s;
  transition: box-shadow 0.25s, -webkit-box-shadow 0.25s;
}

.hoverable:hover {
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.blockquote::before {
  font-family: Arial;
  content: "\201C";
  color: var(--primary-color);
  font-size: 6em;
  position: absolute;
  left: -40px;
  top: -40px;
}

.blockquote::after {
  content: "";
}

.primary-ghost-btn {
  color: var(--primary-color);

  &:hover {
    background: var(--primary-color);
    color: black;
  }
}

.border-none {
  border: none !important;
}

.MuiBadge-badge {
  font-weight: bold;
}

.notification-item:hover {
  background-color: rgba(138, 138, 138, 0.2);
}

.modal-content-container {
  max-width: 90vw;
  width: 1200px;
  height: 95vh !important;
  display: flex;
  justify-content: center;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 32px 16px;
  overflow-y: scroll;
}

.img-zoom-hover img {
  @media (hover: hover) {
    @apply default-transition;
    transform: scale(1);

    &:hover {
      @apply default-transition;
      transform: scale(1.1);
    }
  }
}

.MuiStepLabel-label {
  @apply default-transition;

  font-size: 12px !important;
  color: #8a8a8a !important;
  text-transform: uppercase !important;
  letter-spacing: 1.5px !important;
  line-height: 20px !important;
  font-family: "Open Sans", sans-serif !important;

  &.MuiStepLabel-completed {
    color: #424447 !important;
  }

  &.MuiStepLabel-active {
    color: #fac912 !important;
  }
}

.sortableHelper {
  z-index: 99999999999999999999999 !important;
}

.md\:visible {
  @media (min-width: 768px) {
    display: block !important;
  }
}

.MuiCheckbox-root {
  color: #fac912 !important;
}

.MuiCheckbox-root-secondary {
  color: #8a8a8a !important;
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(250, 201, 18, 0.1) !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #fac912 !important;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #fac90e;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 200px;
  padding: 30px 0 5px;
  background-color: #fac912;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #252525;
  font: 700 16px/1 "Open Sans", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: 50px;
  top: -25px;
  transform: rotate(45deg);

  CheckIcon {
    transform: rotate(-45deg);
  }
}

.floor-fade {
  background-size: cover;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.6)
  );
}

.vspacer {
  width: 2px;
  height: 1px;
  margin-left: 12px;
  margin-right: 12px;
}

.worded-line {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #8a8a8a;
  line-height: 0.1em;
  margin: 10px 0 20px;

  & > span {
    background: #424447;
    padding: 0 10px;
  }
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.recharts-text.recharts-cartesian-axis-tick-value {
  font-size: 12px !important;
}

.metric-row {
  outline: 2px solid !important;
  outline-color: transparent !important;
  transition: outline-color 0.25s cubic-bezier(0.65, 0, 0.45, 1);
}

.metric-row.selected-inf {
  outline-color: rgba(250, 201, 18, 1) !important;
  transition: outline-color 0.25s cubic-bezier(0.65, 0, 0.45, 1);
}

.MuiPickersCalendarHeader-iconButton {
  color: #000 !important;
}

.inf-card-selected {
  border: 1px solid #8a8a8a !important;
  transition: outline 0.25s cubic-bezier(0.65, 0, 0.45, 1);
}
